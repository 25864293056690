import service from '@/utils/request';

// @Tags DicIpshift
// @Summary 创建DicIpshift
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DicIpshift true "创建DicIpshift"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /dicIpshift/createDicIpshift [post]
export var createDicIpshift = function createDicIpshift(data) {
  return service({
    url: "/dicIpshift/createDicIpshift",
    method: 'post',
    data: data
  });
};

// @Tags DicIpshift
// @Summary 删除DicIpshift
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DicIpshift true "删除DicIpshift"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /dicIpshift/deleteDicIpshift [delete]
export var deleteDicIpshift = function deleteDicIpshift(data) {
  return service({
    url: "/dicIpshift/deleteDicIpshift",
    method: 'delete',
    data: data
  });
};

// @Tags DicIpshift
// @Summary 删除DicIpshift
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除DicIpshift"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /dicIpshift/deleteDicIpshift [delete]
export var deleteDicIpshiftByIds = function deleteDicIpshiftByIds(data) {
  return service({
    url: "/dicIpshift/deleteDicIpshiftByIds",
    method: 'delete',
    data: data
  });
};

// @Tags DicIpshift
// @Summary 更新DicIpshift
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DicIpshift true "更新DicIpshift"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /dicIpshift/updateDicIpshift [put]
export var updateDicIpshift = function updateDicIpshift(data) {
  return service({
    url: "/dicIpshift/updateDicIpshift",
    method: 'put',
    data: data
  });
};

// @Tags DicIpshift
// @Summary 用id查询DicIpshift
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.DicIpshift true "用id查询DicIpshift"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /dicIpshift/findDicIpshift [get]
export var findDicIpshift = function findDicIpshift(params) {
  return service({
    url: "/dicIpshift/findDicIpshift",
    method: 'get',
    params: params
  });
};

// @Tags DicIpshift
// @Summary 分页获取DicIpshift列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取DicIpshift列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /dicIpshift/getDicIpshiftList [get]
export var getDicIpshiftList = function getDicIpshiftList(params) {
  return service({
    url: "/dicIpshift/getDicIpshiftList",
    method: 'get',
    params: params
  });
};